import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="FAQ" />
    <div className="container">
      <h1 id="faq" className="mt-5">Frequently Asked Questions</h1>
      <ul className="mt-5">
        <li>
          <h4>I've got a problem with my image credits!</h4>
          <p>Reach out to me on <a href="https://twitter.com/Buntworthy">Twitter</a> or send an email to help@toonify.photos
          </p>
        </li>
        <li>
          <h4>Can I use this model for my own project?</h4>
          <p>Not quite! We have API you can use but it currently only runs the older, <a href="https://toonify.photos/original">lower resolution model</a>.
            But you can check it out <a href="https://justinpinkney.github.io/toonify-api-docs/">here.</a></p>
        </li>
        <li>
          <h4>How does this work?</h4>
          <p>This toonification system is made using deep learning. It uses a deep learning method called Generative Adversarial Networks. 
            In fact it's a rather convoluted combination of StyleGAN2, resolution dependent model interpolation, and pixel2style2pixel.</p>
          <p>At some point I'll write up more of the technical details on <a href="https://www.justinpinkney.com">my blog</a>.</p>
        </li>
        <li>
          <h4>How did you come up with this idea?</h4>
          <p>This all started from some earlier experiments <a href="https://twitter.com/Norod78/status/1297849293299212288">Doron shared on Twitter</a> that got a lot of interest. 
            For a description of those early experiments see <a href="https://www.justinpinkney.com/toonify-yourself/">this blog post on self toonification</a>, 
            the idea is the same, but the method is different (and not really suitable for hosting as a webapp).</p>
          <p>We then made the <a href="https://toonify.photos/original">original Toonify Yourself</a> which got a lot of attention, and since then have been working to improve, producing Toonify HD.</p>
          <p>Fundamentally this is all based on my work in Resolution dependent model interpolation, you can read the <a href="https://arxiv.org/abs/2010.05334">paper on Arxiv</a>.</p>
        </li>
        <li>
          <h4>How do I get good results?</h4>
          <p>The algorithm works best with high resolution images without much noise. Looking straight on to the camera also seem to work best. Something like a corporate headshot tends to work well.</p>
        </li>
        <li>
          <h4>Do you store my photo?</h4>
          <p>No your image is discarded as soon as you get your result. We don't store it or use it for anything other than producing your Toonification.</p>
        </li>
        <li>
          <h4>My face wasn't found!</h4>
          <p>We use the open source <a href="http://dlib.net/">dlib</a> face detector to find faces, it's designed to pick up frontal faces but isn't perfect.</p>
        </li>
        <li>
          <h4>Where did my glasses go?</h4>
          <p>Not many characters from animated films wear glasses so the model seems to have learnt to mostly remove them. 
            It also has problems with bald people, hats, and various other things.
            For the HD model you can try sliding the glasses slider to 5 to restore your spectacles!
          </p>
        </li>
      </ul>

    </div>
  </Layout>
)

export default SecondPage
